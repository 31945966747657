// Init Featherlight on Vimeo attribute elements
jQuery('vimeo-play-btn').featherlight()


{
   // -------
   // Set past dates as disabled and minimum 3 days 
   // notice for Advanced Forms date field
   // -------

   function setCheckoutDatefieldOptions() {
      const datePickers = jQuery('.acf-date-picker input[type="text"]');

      if (!datePickers.length) return

      jQuery(datePickers).each((i, field) => {
         jQuery(field).datepicker('option', 'minDate', '+3')
      })
   }

   window.addEventListener('load', () => {
      setCheckoutDatefieldOptions()
   })


   // -------
   //  Determine Todays Opening Times
   // -------
   const todaysOpeningTimes = document.querySelectorAll('.todays_opening span')
   const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
   const todaysIndex = new Date().getDay()
   const today = weekdays[todaysIndex];

   todaysOpeningTimes.forEach((el) => {
      if (!el.classList.contains(today)) return

      el.classList.add('active')
   })
}

document.addEventListener("DOMContentLoaded", function() {
	var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
	
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}
					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});
		lazyVideos.forEach(function(lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
});