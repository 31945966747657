// ------------------
// Navigation
// ------------------

let $navigation = document.querySelector(".header"),
	$navFade = document.querySelector(".navigation-fade"),
	$menu = document.querySelector(".header__main__menu"),
	$hamburger = document.querySelector(".menu_toggle"),
	_isNavOpen = false,
	navFadeClickEvent = null,
	activeEventListener = false;

$hamburger.addEventListener("click", () => {
	handleNavState();
});

function determineFadeState() {
	if (navFadeClickEvent) {
		$navFade.removeEventListener("click", handleNavState);
	} else {
		$navFade.addEventListener("click", handleNavState);
	}

	// Must be on mobile if we are here
	if (!activeEventListener) {
		window.addEventListener('resize', handleResizingToDesktop)
		activeEventListener = true
	}
}

function handleResizingToDesktop() {
	if (window.innerWidth < 991) return

	_isNavOpen && handleNavState()
	document.querySelector("body").classList.remove("stop-scrolling")
	$menu.setAttribute('aria-hidden', 'false');
	activeEventListener && window.removeEventListener('resize', handleResizingToDesktop)
}

function handleNavState() {
	_isNavOpen = !_isNavOpen;
	determineFadeState();

	$navFade.classList.toggle("nav-active");
	$hamburger.classList.toggle("nav-active");
	$menu.classList.toggle("nav-active");
	$menu.setAttribute('aria-hidden', !_isNavOpen);

	document.querySelector("body").classList.toggle("stop-scrolling");
}