// {
// 	// -----
// 	// Resize OpenTable widget with MutationObserver
// 	// ----
// 	let observer = null;

// 	function openTable() {
// 		const opentable = document.querySelector('oc-component')
// 		const config = { childList: true };

// 		const callback = (mutationList, observer) => {
// 			outer: for (const mutation of mutationList) {
// 				if (mutation.type === 'childList') {
// 					for(const el of mutation.addedNodes) {
// 						if(el.id !== 'ot-reservation-widget') continue 

// 						determineOTWidth()
// 						opentable.classList.add('show')
// 						observer.disconnect()
// 						break outer;
// 					}
// 				}
// 			}
// 		};

// 		observer = new MutationObserver(callback);
// 		observer.observe(opentable, config);
// 	}

// 	function determineOTWidth() {
// 		const opentable = document.querySelector('oc-component')
// 		let innerEl = opentable.querySelector('.ot-dtp-picker') 

// 		if (window.innerWidth > 991) {
// 			innerEl.classList.add('wide')
// 		} else {
// 			innerEl.classList.remove('wide')
// 		}
// 	}

// 	window.addEventListener('resize', determineOTWidth )
// }

{
	let observer = null;

	function watchOT() {
		const opentable = document.querySelector('.ot-container') // Loaded in footer
		const config = { childList: true, subtree: true };
		const callback = (mutationList, observer) => {
			for (const mutation of mutationList) {
				for (const el of mutation.addedNodes) {
					
					if (el.id != 'ot-reservation-widget') continue

					initOTEvents()
					//observer.disconnect()
				}
			}
		};

		observer = new MutationObserver(callback);
		observer.observe(opentable, config);
		console.log(observer, callback)
	}

	function initOTEvents() {
		const OTButtons = document.querySelectorAll('.ot-opener')
		const ot = document.querySelector('.ot-button')

		OTButtons.forEach(btn => {
			console.log('button')
			btn.addEventListener('click', () => {
				ot.click()
				console.log('click')
			})
		})
	}

	watchOT()
	
}