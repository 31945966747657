function navScroll() {
	const doc = document.documentElement

	let curScroll
	let prevScroll = window.scrollY || doc.scrollTop
	let curDirection = 0
	let prevDirection = 0

	let header = document.querySelector('.header')
	let fixHeader = document.querySelector('.fixed')
	let maison = header.querySelector('.header__maison-link')
	let main = header.querySelector('.header__main')
	let toggled
	let threshold = 300

	const calculateVariables = function () {
		header.style.setProperty('--maisonHeight', 'auto')
		setTimeout(() => {
			let maisonHeight = Math.ceil(maison.getBoundingClientRect().height),
				mainHeight = Math.ceil(main.getBoundingClientRect().height)
				console.log(maisonHeight)
			header.style.setProperty('--maisonHeight', maisonHeight + 'px')
			header.style.setProperty('--bgHeight', mainHeight + 'px')
		}, 100)
	}

	const checkScroll = function () {
		curScroll = window.scrollY || doc.scrollTop
		if (curScroll > prevScroll) {
			curDirection = 2
		} else {
			curDirection = 1
		}

		if (curDirection !== prevDirection) {
			toggled = toggleHeader()
		}

		if (toggled) {
			prevDirection = curDirection
		}
		prevScroll = curScroll
	}

	const toggleHeader = function () {
		toggled = true
		if (curDirection === 2 && curScroll > threshold) {
			header.classList.add('hide')
			fixHeader.classList.add('hide')
		} else if (curDirection === 1) {
			header.classList.remove('hide')
			fixHeader.classList.remove('hide')
		} else {
			toggled = false
		}
		return toggled
	}

	calculateVariables()
	

	window.addEventListener("scroll", checkScroll, { passive: false })
	window.addEventListener("resize", () => {
		checkScroll()
		calculateVariables()
	}, { passive: false })

}

{
	navScroll()
}