jQuery('.gallery-slider__carousel').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	pauseOnFocus: false,
	centerMode: true,
	variableWidth: true
});

jQuery('.banner_slides').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	pauseOnFocus: false,
});

jQuery('.slider_home').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	fade: true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	easing: 'ease-in-out',
	speed: 1500,
	
});
